@import './ManageUser/index.scss';
@import './UserDetails/index.scss';

// for editUserDetails Drawer

/* Styles for the editUser drawer component */
.manage_user_drawer .MuiPaper-root {
  width: 31%;
  height: 100vh;
  overflow: hidden;

  /* Responsive adjustments for different screen sizes */

  @media screen and (max-width: 1400px) {
    width: 40%;
  }
  @media screen and (max-width: 992px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    width: 80%;
  }
}

/* Styles for the close button within the filter drawer */
.manage_user_drawer .drawer_close_button .MuiIconButton-root {
  padding: 0px 0px 0px 8px;
  display: flex;
  align-items: center;
  &:focus-visible {
    border-radius: 0px;
  }
}

.manage_user_drawer .drawer_close_button .MuiTypography-root {
  color: $primary;
  font-weight: 500;
}

/* Styles for the divider within the filter drawer */
.manage_user_drawer .MuiDivider-root {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  color: $pane-bg;
}

/* Styles for the edituser heading within the edituser drawer */

.manage_user_drawer .manage_user_header_container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 24px;
}
.manage_user_drawer .manage_user_panel_heading .MuiTypography-root {
  font-size: 24px;
  font-weight: 900;
  color: $grid-black-text;
  margin-top: 15px;
  letter-spacing: 1px;
}
.manage_user_drawer .manage_user_panel_helper_text .MuiTypography-root {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: $grid-black-text;
}

// Manage user forms

.manage_user_form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 90%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;

  .manage_user_form_fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 90%;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: overlay;
    padding: 10px 10px;

    .form_row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    .form_field {
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .form_buttons {
    margin-bottom: 20px;
  }
}

// scrollbar
.manage_user_form_fields::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.manage_user_form_fields::-webkit-scrollbar-thumb {
  background: $scrollbar-grey;
  border-radius: 10px;
}

.manage_user_form_fields::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-grey-light;
}
