.stepper_form_container {
  display: flex;
  flex-direction: column !important;
  padding: 40px;
  gap: 24px;
  width: 100%;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  .stepper_form_container_header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 20px;
    width: 100%;

    .stepper_form_container_header {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.75px;
      color: $secondary;
    }
    .stepper_form_container_helper_text {
      font-size: 16px;
      font-weight: 500;
      line-height: 21.17px;
      letter-spacing: 0.02em;
      color: $secondary;
    }
  }
  .company_info_form_container {
    .company_info_form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 20px;

      .company_info_form_fields {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 90%;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: overlay;
        padding: 10px 10px;

        .form_row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 24px;

          @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        .form_field {
          @media screen and (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
  }
  .more_company_details_form_container {
    .more_company_details_form_fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .form_row {
        .MuiFormLabel-root {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .revenue_target_form_container {
    .revenue_target_form_fields {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  .product_offers_form_container {
    .product_offers_form {
      display: flex;
      flex-direction: column;

      .product_offers_form_fields {
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 90%;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: overlay;
        padding: 2px;

        .form_row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 24px;

          @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        .form_field {
          @media screen and (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
  }

  .terms_and_conditions_form_container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .terms_and_conditions_form {
      display: flex;
      flex-direction: column;

      .terms_and_conditions_form_fields {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px;

        .form_row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 10px;

          .MuiFormControlLabel-root {
            display: flex;
            align-items: start;
            gap: 5px;

            .MuiTypography-root {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 150%;
              color: $secondary;
              margin-top: 9px;
            }
          }

          @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        .form_field {
          @media screen and (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }

    .terms_and_conditions_concent {
      .link {
        color: $primary;
        text-decoration-color: $primary;
        margin: 0px 5px;
        text-underline-offset: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
  .stepper_form_container {
    padding: 30px;
  }
}

@media (max-width: 576px) {
  .stepper_form_container {
    padding: 25px;
  }
}
