.forgot_password_container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $white-text;

  .forgot_password_form_wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 52%;

    @media (max-width: 1500px) {
      width: 60%;
      gap: 25px;
    }

    @media (max-width: 1000px) {
      width: 70%;
    }
  }

  .forgot_password_navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .navigation_btn {
      border-radius: 0;
      color: $primary;
      text-transform: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      padding: 0px;
      gap: 8px;
      text-decoration-line: underline;

      .MuiButton-icon {
        height: 24px;
        width: 24px;
        align-items: center;
        margin-right: 0px;
      }

      .MuiButton-icon .MuiSvgIcon-root {
        font-size: 24px !important;
      }
    }
  }

  .form_description {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form_header {
      .header_title {
        font-weight: 700;
        color: $secondary;
        font-size: 32px;
        line-height: 150%;
      }
    }
    .form_context p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $grid-black-text;
    }
  }

  .forgot_password_form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .forgot_password_form_fields {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 1500px) {
        gap: 16px;
      }
    }

    .form_field {
      .forgot_password_input_label {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
        margin-bottom: 8px;
      }
    }

    .forgot_password_input_field {
      .MuiInputBase-input {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
        padding: 16px;
        height: 1rem;
      }
      .MuiInputBase-root {
        border-radius: 0;
        background: $pane-bg;
        border: 1px solid $blue-grey;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      &.forgot_password_input_field_error .MuiInputBase-root {
        border: 1px solid $error_tomato !important;
      }

      &.forgot_password_input_field_error .MuiFormHelperText-root {
        color: $error_tomato;
        margin: 0;
        padding-top: 32px;
        background-color: $white-text;
        font-size: 16px;
      }
      .MuiOutlinedInput-root:focus-within {
        border: none;
        box-shadow: 0px 0px 0px 2px $pink-focus;
        outline: 1px solid $white;
        border-radius: 0px;
        z-index: 1;
      }
    }

    .forgot_password_form_error {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $error_tomato;
      margin-top: 32px;
    }

    .form_button {
      margin-top: 32px;
    }

    .form_button .continue_button {
      width: 100%;
      text-transform: none;
      background-color: $primary;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: $white-text;
      border-radius: 0px;
      padding: 16px;

      &:focus-visible {
        outline: 1px solid white;
        box-shadow: 0px 0px 0px 2px $pink-focus;
      }
    }
  }
}
