.capsule_tab_container {
  display: flex;
  flex-direction: column !important;

  .custom_tab_panel {
    .custom_tabs {
      align-items: center;

      .MuiTabs-flexContainer {
        gap: 10px;

        .MuiButtonBase-root {
          min-height: 0px;
          text-transform: capitalize;
          color: $secondary;
          border-radius: 100px;
          padding: 8px 16px 8px 16px;
          background-color: $white-text;
          border: 1px solid $mercury;
        }

        .MuiButtonBase-root.Mui-selected {
          font-weight: 700;
          background-color: $white-blue;
          border: 1px solid $primary;
        }

        .MuiButtonBase-root {
          &:focus-visible {
            box-shadow: none;
            outline: none;
            border: 2px solid $pink-focus !important;
          }
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .capsule_tab_container {
    .custom_tab_panel {
      .custom_tabs {
        .MuiTabs-flexContainer {
          flex-direction: column;
        }
      }
    }
  }
}
