.error_parent_container {
  height: 100vh;
  justify-content: center;
  align-items: center;

  .MuiCard-root {
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;

    .MuiCardContent-root {
      padding: 20px;
    }

    .error_card_details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .error_code.MuiTypography-root {
        font-size: 80px;
        font-weight: 600;
        color: $primary;
        height: 90px;
      }
      .error_heading.MuiTypography-root {
        font-size: 20px;
        font-weight: 600;
        color: $secondary;
      }
      .error_message.MuiTypography-root {
        text-align: center;
        font-weight: 500;
        color: $secondary;
      }
      .MuiButtonBase-root {
        padding: 10px;
        border: 1px solid $primary;
        border-radius: 0px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
        margin: 15px 0px;
        color: $secondary;
      }
    }
  }
}
