// More Products Card Section

.more_products_card_container {
  .more_products_static_card.MuiCard-root {
    width: 340px;
    padding: 20px 15px 50px 15px;

    .more_products_card_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .more_products_logo {
        height: 120px;
      }

      .coming_soon_heading {
        width: 250px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      .coming_soon_text {
        text-align: center;
        color: $grid-black-text;
        width: 235px;

        .sales_link{
            color: $primary;
        }
      }
    }
  }
}
