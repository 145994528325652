.layout_container {
  height: 100vh;
}
.dashboard_container {
  display: flex;
  min-height: calc(100vh - 64px);

  &.hidden_outlet {
    display: none;
  }

  #main-content {
    background-color: $pane-bg;
    width: 100%;
  }
}
