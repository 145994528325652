//Multi Value Container
.ag-row .ag-cell .multiple_value_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: -webkit-fill-available;

  .name {
    width: -webkit-fill-available;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $secondary;
  }

  .type {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $blue-grey;
  }
}

//CellWithlink
.ag-row .ag-cell .grid_cell_link {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

// More Options
.ag-row .ag-cell .more_option_container {
  .more_option_btn {
    padding: 0px;

    &:hover {
      background-color: inherit;
    }
  }
}

.more_option_popup {
  .MuiPaper-root-MuiPopover-paper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $pane-bg;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }

  .more_option_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .more_option_list_items {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      padding: 8px 24px 8px 16px;
      justify-content: end;

      a,
      span {
        text-decoration: none;
        color: $secondary;
        text-align: end;
        cursor: pointer;
      }
    }

    .more_option_list_items:hover {
      background-color: $primary;

      a,
      span {
        color: $white-text;
      }
    }
  }
}

// customHeader

.ag-header-cell .custom_header_container {
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .custom_header_icons_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-left: 4px;
  }

  .custom_header_icon_button {
    padding: 0;
    height: 10px;
    width: 15px;

    &:hover {
      background-color: inherit;
    }
  }

  .custom_header_icons_wrapper .custom_header_icon {
    cursor: pointer;
    font-size: 16px;
    color: $secondary;
    opacity: 0.4;

    &.icon_fill {
      opacity: 1;
    }
  }
}

.cell_with_border_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: $white-text;
  width: fit-content !important;

  .cell_value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $secondary;
  }
}

.ag-overlay {
  top: 20px !important;

  .ag-react-container {
    height: 100%;
    width: 100%;
  }
}

.custom_no_rows_overlay {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 100%;
  width: 100%;
  background: $white-text;

  .custom_message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $grid-black-text;
  }
}

.custom-loading-overlay {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 100%;
  width: 100%;
  background: $white-text;

  .loader_text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $grid-black-text;
  }
}

.grid-tooltip {
  background-color: $white;
  padding: 8px 12px 8px 12px;
  border-radius: 2px;
  width: 200px;
  height: fit-content;
  color: $secondary;
}

.cell_value_render {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

// for Download Action Button
.cell_with_download_action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
  flex-wrap: wrap;

  .download_button {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $primary;
    text-transform: capitalize;
    
    &.Mui-disabled{
      color: rgba(0, 0, 0, 0.56) !important;
    }
  }
  .custom-loader{
    margin-top: 0px !important;
    height: 100%;

    span{
      height: 20px !important;
      width: 20px !important;
    }
  }
}

// for Cell with Icon
.cell_with_File_Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
