.contact_helper {
  width: 50%;
  margin-bottom: 20px;
  .contact_helper_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: $secondary;

    .email_address {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      text-align: center;
      text-decoration-skip-ink: none;
    }
  }
}

@media (max-width: 1600px) {
  .contact_helper {
    width: 60%;
  }
}

@media (max-width: 1440px) {
  .contact_helper {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .contact_helper {
    width: 85%;
  }
}
