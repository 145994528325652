.account_settings_parent_container {
  display: flex;
  justify-content: center;
  height: inherit;
  background-color: $white-text;
  padding: 0px 35px;

  @media screen and (max-width: 1000px) {
    padding: 0px 60px;
  }

  @media screen and (max-width: 800px) {
    padding: 0px 30px;
  }

  .account_settings_children_container {
    width: 935px;
    height: fit-content;
    margin-top: 70px;
    gap: 20px;
  }

  .account_settings_heading {
    .MuiTypography-root {
      font-size: 32px;
      font-weight: 700;
    }
  }

  .account_settings_tabs {
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }

    .custom_tab_panel_container {
      gap: 20px;

      .custom_tabs .MuiButtonBase-root {
        padding: 0;
        align-items: flex-start;
        justify-content: flex-start;
        text-transform: capitalize;
        margin: 10px 2px;
        color: $secondary;
      }

      .custom_tabs .MuiButtonBase-root.Mui-selected {
        font-weight: 700;
        color: $primary;
      }

      .custom_tabs .MuiTab-root {
        min-height: 0px;
      }

      .MuiTabs-indicator {
        display: none;
      }

      .custom_tab_panel_children {
        max-width: 695px;

        .account_details_container {
          .MuiPaper-root {
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: 695px;
            min-height: 200px;

            @media screen and (max-width: 768px) {
              width: 600px;
            }

            @media screen and (max-width: 650px) {
              width: 500px;
            }

            @media screen and (max-width: 550px) {
              width: 450px;
              padding: 20px;
            }

            @media screen and (max-width: 500px) {
              width: 350px;
            }
          }

          .custom-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
          }

          .account_details_main_heading.MuiTypography-root {
            width: 91%;
            margin: 0 auto;
            font-size: 20px;
            margin-top: 20px;
            font-weight: 700;
          }

          .MuiCardContent-root {
            padding: 0;
            width: 91%;
            margin: 0 auto;

            .account_details_content_grid{
              gap: 15px;
            }
          }

          .account_details_header {
            margin-bottom: 20px;

            .MuiTypography-root {
              font-weight: 700;
              text-transform: uppercase;
              font-size: 14px;
            }

            .MuiDivider-root {
              border: 1px solid $mercury;
              width: 100%;
              margin-top: 5px;
            }
          }

          .account_details_values {
            margin-bottom: 25px;
            padding-right: 5px;

            .account_details_values_key {
              font-weight: 600;
              color: $grid-black-text;
            }
          }
        }
      }
    }
  }
}