.stepper_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;

  .stepper_grid {
    .MuiStepper-root {
      .MuiStep-root {
        .MuiStepLabel-root {
          .MuiStepLabel-iconContainer {
            .MuiSvgIcon-root {
              border: 1px solid $primary;
              border-radius: 100%;
              fill: $white;
              .MuiStepIcon-text {
                font-weight: 700;
                fill: $secondary;
              }
            }
          }
          .MuiStepLabel-labelContainer {
            .MuiStepLabel-label {
              color: $blue-grey;
              font-weight: 700;
            }
          }

          .MuiStepLabel-iconContainer.Mui-active {
            .MuiSvgIcon-root {
              border: none;
              fill: $secondary;
              .MuiStepIcon-text {
                fill: $white;
              }
            }
          }
          .MuiStepLabel-labelContainer {
            .MuiStepLabel-label.Mui-active {
              color: $secondary;
              cursor: pointer;
            }

            .MuiStepLabel-label.step-label-active {
              color: $secondary !important;
            }
          }

          .MuiStepLabel-iconContainer.Mui-completed {
            .MuiSvgIcon-root {
              border: none;
              fill: $primary;
              .MuiStepIcon-text {
                fill: $white;
              }
            }
          }
          .MuiStepLabel-labelContainer {
            .MuiStepLabel-label.Mui-completed {
              color: $primary;
              text-decoration: underline;
              cursor: pointer;
              text-underline-offset: 4px;
            }
          }
        }
        &:focus-visible {
          box-shadow: 0 0 0px 2px $pink-focus;
          outline: 1px solid $white-text;
          outline-offset: 0px;
        }
      }
    }
    .MuiStepConnector-root {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .stepper_container {
    .MuiStepper-vertical {
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
