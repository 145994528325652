.signup {
  height: 100%;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;

  .login_account {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 0px;
    gap: 8px;
    margin-right: 32px;
    margin-top: 50px;

    .login_account_link {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-decoration-line: underline;
      color: $primary;
    }
  }
  .signup_container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 40px;

    .signup_form_wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 52%;
    }

    .form_description {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .form_header {
        .header_title {
          font-weight: 700;
          font-size: 48px;
          line-height: 64px;
          color: $grid-black-text;
        }
      }

      .form_context p {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
      }
    }

    .signup_form {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .signup_form_heading .MuiTypography-root {
        font-weight: 700;
        font-size: 32px;
        color: $secondary;
      }

      .signup_form_submit_button {
        margin-bottom: 30px;
        padding: 16px;
        display: flex;
        align-items: center;
        background-color: $primary;
        color: $white;
        border-radius: 0;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        text-transform: none;
      }

      .signup_input_fields_container {
        .MuiFormLabel-root {
          color: $grid-black-text;
          margin-bottom: 5px;
        }

        .MuiInputBase-input {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: $secondary;
          padding: 16px;
          height: 1rem;
        }

        .MuiInputBase-root {
          border-radius: 0;
          background: $pane-bg;
          border: 1px solid $blue-grey;
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        .MuiOutlinedInput-root:focus-within {
          border: none;
          box-shadow: 0px 0px 0px 2px $pink-focus;
          outline: 1px solid $white;
          border-radius: 0px;
          z-index: 1;
        }
      }

      .signup_password_rules {
        .MuiListItem-root {
          display: flex;
          align-items: baseline;
          padding: 0;
          margin-bottom: 5px;
          color: $grid-black-text;

          &.untouched {
            color: $blue-grey;

            .circle {
              background: $blue-grey;
            }
          }

          &.valid .circle {
            background: $primary;
          }

          &.invalid .circle {
            background: $error_tomato;
          }
        }

        .circle {
          background: $primary;
          border-radius: 50%;
          margin-right: 10px;
        }

        .circle_normal {
          width: 10px;
          height: 10px;
        }

        .circle_special {
          width: 15px;
          height: 10px;
        }
      }

      .MuiFormHelperText-root {
        margin-left: 0;
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1600px) {
  .signup .signup_container .signup_form_wrapper {
    width: 60%;
  }
}

@media (max-width: 1440px) {
  .signup .signup_container .signup_form_wrapper {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .signup .signup_container .signup_form_wrapper {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .signup .signup_container .signup_form_wrapper {
    width: 65%;
  }
}
@media (max-width: 500px) {
  .signup .signup_container .signup_form_wrapper {
    width: 75%;
  }
}
