.manage_users {
  padding: 54px;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  gap: 32px;
  height: 100%;

  .manage_users_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .add_new_user_button {
      border-radius: 0px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-transform: none;
      background: $primary;
      padding: 16px;
      color: $white-text;
    }
  }

  .manage_users_header h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: $secondary;
    border: none;
    outline: none;
  }

  .manage_user_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px;
    gap: 16px;
    background-color: $white;
    width: 100%;

    .manage_user_divider {
      width: 100%;
      border: 1px solid $pane-bg;
    }
  }
}
