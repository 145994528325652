.ag_grid_pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  .pagination_start_container {
    width: 40%;
    height: 29px;
    background: $white-text;
    flex: 1;
  }
}

.pagination_dropdown {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;

  .pagination_dropdown_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $secondary;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.pagination_dropdown_select {
  width: 55px !important;
  height: 29px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $secondary  !important;
  border-radius: 0px !important;
  background-color: $pane-bg;
  border: 1px solid $blue-grey;

  &:focus-visible {
    border: none;
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    outline: none;
  }

  .pagination_dropdown_box {
    padding: 4px 8px !important;
    border: none;
  }

  .pagination_dropdown_box:focus-visible {
    box-shadow: 0px 0px 0px 1px $pink-focus;
    outline-offset: -1px;
    outline: 1px solid $white;
    border-radius: 0px;
    outline-offset: -1px;
  }

  svg {
    height: 18px;
    width: 18px;
    font-size: 18px;
  }

  .pagination_dropdown_box.MuiSelect-select {
    text-overflow: inherit;
  }
}

.pagination_option_menu_paper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: $pane-bg  !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  width: 55px !important;

  .pagination_dropdown_menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 55px !important;
  }

  .pagination_dropdown_items {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: $secondary;

    &.Mui-selected {
      font-weight: 700;
      background-color: inherit;
    }

    &:focus-visible {
      box-shadow: inset 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      outline-offset: -2px;
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.pagination_details_text {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
  text-align: right;
  color: $grid-black-text;
}

.grid_pagination_buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;

  .pagination_btn {
    min-width: 0;
    color: $secondary;
    border: 1px solid $button-grey;
    width: 60px;
    height: 29px;
    padding: 4px 8px;
    gap: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    border-radius: 0px;
    text-transform: none;

    &.first_btn,
    &.last_btn {
      width: 45px !important;
    }

    &.prev_btn {
      .MuiButton-startIcon {
        margin-right: 0px;
        margin-left: -5px;
        height: 15px;
        width: 15px;

        svg {
          font-size: 15px;
        }
      }
    }

    &.next_btn {
      .MuiButton-endIcon {
        height: 15px;
        width: 15px;
        margin-left: 0px;

        svg {
          font-size: 15px;
        }
      }
    }
  }

  .pagination_number_btn {
    min-width: 25px;
    height: 29px;
    border: 1px solid $button-grey;
    padding: 4px 8px;
    border-radius: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $secondary;
  }

  .btn_selected {
    border: 1px solid $secondary;
    background-color: $secondary;
    color: $white !important;

    &:hover {
      background-color: $secondary;
    }
  }
}

// media query for responsiveness

@media (max-width: 1100px) {
  .ag_grid_pagination {
    justify-content: center;

    .pagination_start_container {
      display: none;
    }
  }
}