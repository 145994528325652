.Toastify__toast-container {
  height: 56px;
  margin: 0px;
  padding: 8px 0;
  width: auto;
  .custom-toast-container {
    margin: 0px;
    padding: 0px;
    background: $white;
    border: 2px solid $success;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 56px !important;

    .custom-toast-body {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 8px 24px;
      gap: 16px;
      margin: 0px;

      .Toastify__toast-icon {
        margin: 0 !important;
      }

      .toast_message {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
      }
    }
  }
}
