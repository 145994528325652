.skip_link_container {
  position: absolute;
  top: -100px;
  left: 0;
  padding: 10px;
  z-index: 100;
  background: $white-text;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
  .skip_link {
    color: $secondary !important;
    text-decoration: none !important;
    transition: top 0.3s;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0.02em !important;
  }
  &:focus-within {
    top: 15px;
    left: 15px;
  }
}
