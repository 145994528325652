.user_details_parent_container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  height: 100%;

  .user_details_child_container {
    display: flex;
    flex-direction: column;
    width: 51%;
    justify-content: space-between;
    margin-top: 14%;
    gap: 40px;

    .user_details_heading {
      font-size: 48px;
      font-weight: 700;
      color: $grid-black-text;
    }

    .user_details_form_container {
      .user_details_form {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
      .user_details_form_fields {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form_field {
          .MuiFormHelperText-root {
            background-color: $white;
          }
        }

        .title_field {
          .MuiInputBase-root {
            width: 45%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .user_details_child_container {
      width: 70%;
      gap: 30px;
      .user_details_heading {
        font-size: 42px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .user_details_child_container {
      .user_details_form_container {
        .user_details_form_fields {
          .title_field {
            .MuiInputBase-root {
              width: 55%;
            }
          }
        }
      }
    }
  }
}
