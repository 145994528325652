.quote_creation_container {
  width: 100%;
  .add_new_quote_parent_container {
    justify-content: center;
    margin-top: 35px;

    .add_new_quote_child_container {
      width: 50%;
      box-shadow: 0px 4px 4px 0px $black-russian;
      padding: 40px;
      margin-bottom: 120px;

      .add_new_quote_text {
        color: $primary;
        font-size: 14px;
        font-weight: 700;
      }

      .add_new_quote_heading {
        color: $secondary;
        font-weight: 700;
        font-size: 32px;
      }

      .MuiDivider-root {
        border: 1px solid $pane-bg;
        margin: 15px 0px;
      }

      .add_new_quote_capsule_heading {
        font-weight: 600;
        color: $grid-black-text;
      }
    }

    .existing_opportunity_container {
      .existing_opportunity_grid_container {
        .selected_row {
          background-color: $secondary;

          .ag-cell {
            border-left: 1px solid $white;
            border-right: 1px solid $white;
            color: $white;
          }

          .MuiRadio-root.Mui-checked {
            color: $white;
          }
        }
      }
    }
  }
  .bottom_panel_buttons {
    align-items: center;
    width: 50%;
    justify-content: space-between;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .quote_creation_container {
    .add_new_quote_parent_container {
      .add_new_quote_child_container {
        width: 90%;

        .add_new_quote_capsule_heading {
          margin-bottom: 10px;
        }
      }
    }
    .bottom_panel_buttons {
      width: 70%;
    }
  }
}
