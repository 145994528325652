.c-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.w-clr {
  color: $white;
}

.flex-center {
  @include flex-center;
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 10px;
}

.ft-14 {
  font-size: 14px;
}

.ft-12 {
  font-size: 12px;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.flex-space {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.placeholder-text {
  color: $gray !important;
}
