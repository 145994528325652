.verify_otp {
  height: 100%;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;
  align-items: center;

  .otp_verification_wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 52%;
    gap: 40px;

    .back_button {
      width: 100%;
      .back_button_link {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
        color: $primary;
        width: 172px;

        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .back_button_link {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-decoration-line: underline;
    color: $primary;
  }
  .verify_otp_container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .form_description {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .form_header {
        .header_title {
          font-weight: 700;
          font-size: 48px;
          line-height: 64px;
          color: $grid-black-text;

          @media (max-width: 1500px) {
            font-size: 35px;
          }
        }
      }

      .form_helper_text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 16px;
        gap: 10px;

        background: $light-blue;
        border: 1px solid $winter;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: $secondary;
          width: 100%;
          overflow-wrap: anywhere;

          .email_address {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }
      }
    }

    .otp_verification_form {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;

      .otp_verification_form_resend_code_button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiButtonBase-root {
          width: fit-content;
          height: fit-content;
          padding: 0;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .btn_disabled.MuiButtonBase-root {
          color: $blue-grey;
        }

        .btn_enabled.MuiButtonBase-root {
          color: $primary;
        }

        .MuiTypography-root {
          color: $secondary;
        }
      }

      .otp_verification_form_submit_button {
        padding: 16px;
        gap: 10px;
        background-color: $primary;
        border: none;
        border-radius: 0;
        color: $white;
        font-weight: 700;
        width: 100%;
        text-transform: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        width: 100px;

        &:focus-visible {
          box-shadow: 0px 0px 0px 2px $pink-focus;
          outline: 1px solid $white;
          outline-offset: 0px;
        }

        &.button_disabled {
          background-color: $blue-grey;
          color: $white-text;
        }

        &.button_loader {
          background: $primary !important;
          color: $white-text;
        }

        &:hover {
          background-color: $primary;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .verify_otp .otp_verification_wrapper {
    width: 60%;
  }
}

@media (max-width: 1440px) {
  .verify_otp .otp_verification_wrapper {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .verify_otp .otp_verification_wrapper {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .verify_otp .otp_verification_wrapper {
    width: 65%;
  }
}
@media (max-width: 500px) {
  .verify_otp .otp_verification_wrapper {
    width: 75%;
  }
}
