.order_summary {
  padding: 54px;
  background-color: $white-text;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  gap: 24px;
  
  .MuiButtonBase-root.back_navigation_btn {
    width: 71px;
    display: flex;
    justify-content: flex-start;
  }

  .order_summary_details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.order_summary_details .order_summary_grid_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;
  background-color: $white;

  .custom_tab_panel_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    .custom_tab_panel .custom_tabs {
      min-height: 24px;
    }

    .custom_tab_panel .custom_tabs .MuiTabs-flexContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 24px;
      flex-wrap: wrap;
    }

    .custom_tab_panel .custom_tabs .custom_each_tab {
      padding: 0px 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $secondary;
      min-height: 24px;
      text-transform: none;

      &:focus-visible {
        box-shadow: inset 0 0 0px 2px $pink-focus;
        outline-offset: -2px;
        box-shadow: inset;
      }
    }

    .custom_tab_panel .custom_tabs .Mui-selected.custom_each_tab {
      color: $primary;
    }

    .custom_tab_panel .custom_tabs .MuiTabs-indicator {
      display: none !important;
    }

    .custom_tab_panel_divider {
      border: 1px solid $pane-bg;
      width: 100%;
    }

    .custom_tab_panel_children {
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 32px;
      width: 100%;

      &.panel_children_disabled {
        display: none !important;
      }
    }
  }

  .custom_tab_panel_children {
    .financial_details_fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 40px;
      flex-wrap: wrap;
    }
  }

  .order_detail_product_grid_container {
    display: flex;
    flex-direction: column !important;
    gap: 16px;
  }
}

// media query for responsiveness
@media (max-width: 700px) {
  .order_summary_details .order_summary_grid_container {
    padding: 24px 10px;
  }
}

@media (max-width: 500px) {
  .order_summary {
    padding: 30px;
  }

  .order_summary_details .order_summary_grid_container {
    padding: 24px 0px;
  }
}

@media (max-width: 400px) {
  .order_summary {
    padding: 20px;
  }
}