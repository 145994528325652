.no_result_found {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 100%;
  width: 100%;
  padding: 20px;

  .custom_message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $grid-black-text;
  }
}
