.dashboard_parent_container {
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
  background-color: $white-text;

  .dashboard_child_container {
    width: 93%;
    margin-top: 24px;

    .custom-loader {
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .dashboard_main_heading {
      margin-bottom: 20px;

      .MuiTypography-root {
        font-size: 48px;
        font-weight: 700;
        color: $grid-black-text;
      }
    }

    .dashboard_card_main_grid {
      .left_grid_container {
        .left_card .MuiCard-root {
          width: 97%;
          height: 126px;
          margin-bottom: 20px;
          border-radius: 8px;
        }
      }

      .dashboard_right_card {
        .MuiCard-root {
          height: 93.5%;
          border-radius: 8px;
        }
      }
    }

    .dashboard_cardcontentlabel_grid {
      .MuiTypography-root {
        font-weight: 700;
      }
    }

    .dashboard_cardcontentvalue_grid {
      .MuiTypography-root {
        font-weight: 700;
        font-size: 40px;
      }
    }

    .pending_fulfillment_card {
      background-color: $golden-yellow;
    }
    .fulfillment_card {
      background-color: $winter;
    }
    .credit_hold_card {
      background-color: $error_tomato;

      .MuiTypography-root {
        color: $white;
      }
      .MuiTypography-root {
        color: $white;
      }
    }
    .billed_card {
      background-color: $green;
    }
    .total_orders_card {
      background-color: $secondary;
      .MuiTypography-root {
        color: $white;
      }
      .MuiTypography-root {
        color: $white;
      }
    }

    .dashboard_feature_grid {
      padding: 30px 0px;
      border: 1px solid $button-grey;
      margin-bottom: 20px;

      .feature_img {
        padding: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .feature_details {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: 0px 15px 0px 15px;

        .details_header.MuiTypography-root {
          font-size: 32px;
          font-weight: 700;
        }
      }

      .feature_link {
        color: $primary;
      }
      .feedback_email {
        font-weight: 700;
        word-break: break-word;
      }
    }
  }
  @media screen and (max-width: 960px) {
    .dashboard_child_container {
      .dashboard_card_main_grid {
        .dashboard_right_card {
          .MuiCard-root {
            width: 97%;
          }
        }
      }
    }

    .dashboard_feature_grid {
      height: fit-content;
      width: 97%;
      padding: 20px !important;

      .feature_img {
        display: none;
      }
      .feature_details {
        .details_header.MuiTypography-root {
          font-size: 28px;
        }
      }

      .feature_link {
        color: $primary;
      }
    }
  }
}

.unregistered_partner_dashboard {
  width: 600px;
  display: flex;
  flex-direction: column !important;
  gap: 15px;

  .welcome_header {
    font-size: 64px;
    font-weight: 700;
  }

  .welcome_text1, .welcome_text2 {
    font-size: 18px;
    color: $black1;
    strong{
      font-weight: 700;
      font-size: 18px;
      color: $black1;
      margin: 0 5px; 
    }
  }

  .partner_application_grid {
    border: 1px solid $blue-grey;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    .partner_application_header{
      font-size: 20px;
      color: $grid-black-text;
      font-weight: 700;
    }

    .partner_application_text{
      color: $black1;
    }

    .partner_application_btn{
      background-color: $primary;
      color: $white;
      padding: 15px;
      border-radius: 0px;
      text-transform: none;
      font-size: 16px;
    }
  }
}
