.text_overlay_image {
  height: 100%;
  display: flex;
  justify-content: center;
  background: url('../../../../../images/login_bg_img.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .static_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
    height: 100%;
    width: 66%;
    flex-wrap: wrap;
    color: $white-text;

    @media (max-width: 1900px) {
      width: 85%;
      gap: 20px;
    }

    @media (max-width: 1500px) {
      width: 71%;
      gap: 20px;
    }

    @media (max-width: 1024px) {
      width: 80%;
      gap: 15px;
    }
  }

  .static_content .content_logo {
    height: 105px;
    width: 430px;

    @media (max-width: 1500px) {
      height: 70px;
      width: 260px;
    }

    @media (max-width: 1024px) {
      height: 50px;
      width: 175px;
    }
  }

  .static_content {
    .static_content_upper_grid {
      display: flex;
      gap: 30px;
      align-items: center;

      .MuiDivider-root {
        border: 1px solid $white;
      }

      @media (max-width: 1500px) {
        gap: 25px;
      }
    }
    .content_text {
      font-weight: 500;
      font-size: 32px;
      line-height: 52.93px;

      @media (max-width: 1500px) {
        font-size: 22px;
        line-height: 40px;
      }

      @media (max-width: 1024px) {
        font-size: 17px;
        line-height: 27px;
      }
    }

    .content_header {
      font-weight: 500;
      font-size: 56px;
      line-height: 60px;
      text-transform: uppercase;
      color: $winter;

      @media (max-width: 1500px) {
        font-size: 40px;
        line-height: 45px;
      }

      @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 25px;
      }
    }

    .underline_theame {
      width: 100%;
    }
  }

  .background_image {
    height: 100%;
    width: 100%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// media queries for responsiveness
