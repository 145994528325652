.quote_details {
  padding: 54px;
  background-color: $white-text;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  gap: 24px;

  .MuiButtonBase-root.back_navigation_btn {
    width: 71px;
    display: flex;
    justify-content: flex-start;
  }

  .quote_details_summary {
    display: flex;
    flex-direction: column;
  }
}

.quote_details_loader .custom-loader {
  height: 560px;
  align-items: center;
}

@media (max-width: 500px) {
  .quote_details {
    padding: 20px;
  }
}
