.login {
  height: 100%;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;

  .create_account {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 0px;
    gap: 8px;
    margin-right: 32px;
    margin-top: 50px;

    .create_account_link {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-decoration-line: underline;
      color: $primary;
    }
  }
  .login_container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    height: 100%;

    .login_form_wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 52%;

      @media (max-width: 1500px) {
        width: 60%;
        gap: 25px;
      }

      @media (max-width: 1000px) {
        width: 70%;
      }
    }

    .form_description {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .form_header {
        .header_title {
          font-weight: 700;
          font-size: 40px;
          line-height: 53px;
          color: $secondary;

          @media (max-width: 1500px) {
            font-size: 35px;
          }
        }

        .form_greeting {
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: $primary;
          letter-spacing: 1px;
        }
      }

      .form_context p {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
      }
    }

    .login_form {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .login_form_fields {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: 1500px) {
          gap: 16px;
        }
      }

      .forgot_password {
        font-size: 16px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        text-decoration-line: underline;

        a {
          color: $primary;
        }
      }

      .login_form_error {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $error_tomato;
        margin-top: 32px;
      }

      ._login_form_button {
        margin-top: 32px;
      }

      ._login_form_button .login_button {
        width: 100%;
        text-transform: none;
        background-color: $primary;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: $white-text;
        border-radius: 0px;
        padding: 16px;

        &:focus-visible {
          outline: 1px solid white;
          box-shadow: 0px 0px 0px 2px $pink-focus;
        }
      }
    }
  }
}
