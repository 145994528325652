.bottom_panel_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  height: 100px;
  box-shadow: 0px -4px 20px 0px $semi-transparent-navy-blue;
  z-index: 1000;
  display: flex;

  .bottom_panel_prev_button {
    .MuiButtonBase-root {
      border: 1px solid $primary;
    }

    .btn_hidden.MuiButtonBase-root {
      display: none;
    }
  }

  .bottom_panel_next_button {
    .MuiButtonBase-root {
      color: $white;
      background-color: $primary;
      padding: 8px 16px;
      text-transform: none;
    }
    .Mui-disabled.MuiButtonBase-root {
      background-color: $columbian-blue !important;
      color: $white !important;
    }
    .button_loading {
      cursor: default;
    }
  }

  .MuiButtonBase-root {
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 0px;
  }
}
