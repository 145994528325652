.grid_searchby_container {
  margin-bottom: 10px;
  padding: 0px;

  .grid_search_dropdown {
    width: 190px;
    height: 48px;
    border: 1px solid $blue-grey;
    font-size: 16px;
    font-weight: 500;
    color: $grid-black-text;
    border-radius: 0px;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .search_dropdown_box {
      padding: 0px 12px !important;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .search_dropdown_box:focus, .search_dropdown_box:focus-visible {
      box-shadow: 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      border-radius: 0px;
      z-index: 1;
    }
  }

  .grid_search_bar_field .MuiOutlinedInput-root {
    height: 48px;
    width: 354px;
    border-style: solid;
    border-color: $blue-grey;
    border-width: 1px 1px 1px 0px;
    border-radius: 0px;
    background: #f0f0f0;
    color: $blue-grey;
    font-weight: 500;

    @media screen and (max-width: 490px) {
      width: 100px;
    }
  }

  .dropdown_not_applicable .MuiOutlinedInput-root {
    border-left-width: 1px;
  }

  .grid_search_bar_field .search_icon svg {
    color: $secondary;
  }
  .grid_search_bar_field .MuiOutlinedInput-root:focus-within {
    border: none;
    box-shadow: 0px 0px 0px 2px $pink-focus;
    outline: 1px solid $white;
    border-radius: 0px;
    z-index: 1;
  }

  .searchby_container{
    gap: 10px;
  }

  .grid_search_button {
    width: 87px;
    height: 48px;
    padding: 16px;
    border: 1px solid $primary;
    color: $secondary;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    border-radius: 0px;
    text-transform: none;

    &:hover {
      background-color: $primary;
      color: $white;
      border: none;
    }
  }
}

.search_dropdown_menu_paper {
  border-radius: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $white-text;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  .search_dropdown_menu {
    width: 100%;
    gap: 10px;
    padding: 6px 0px;

    .search_dropdown_items {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $grid-black-text;
    }
    .search_dropdown_items.Mui-selected {
      font-weight: 700;
      background-color: inherit;
    }
    .search_dropdown_items:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }

    .search_dropdown_items:focus-visible {
      border: none;
      box-shadow: inset 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      outline-offset: -2px;
      border-radius: 0px;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.grid_search_bar_field {
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .search_bar_field_box {
    .MuiInputBase-input {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $secondary;
    }
    &.Mui-error{
      border: 1px solid $error_tomato;
    }

    .clear_search_btn svg{
      height: 18px;
      width: 18px;
      color: $primary;
    }
  }
}

.custom-helper-text {
  margin: 0 !important;
  text-align: left;
  color: $error_tomato;
}


// media query for responsiveness

@media (max-width: 950px) {
  .grid_searchby_container {
    
    .grid_search_bar_field .MuiOutlinedInput-root{
      border-width: 1px;
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .grid_searchby_container .grid_search_dropdown {
    width: 160px;
    height: 40px;
    font-size: 13px;
  }
  .grid_searchby_container .grid_search_bar_field .MuiOutlinedInput-root{
    font-size: 14px;
    padding-left: 5px;
    height: 40px;
  }
  .grid_searchby_container .grid_search_button{
    font-size: 14px;
    width: 70px;
    height: 40px;
  }
}
