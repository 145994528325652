.partner_program_price_list_parent_container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: $white;
  height: 100%;

  .partner_program_price_list_child_container {
    width: 93%;
    margin-top: 50px;
    gap: 13px;
    margin-bottom: 30px;

    .partner_program_price_list_main_heading {
      .MuiTypography-root {
        color: $secondary;
        font-size: 36px;
        font-weight: 700;
        border: none;
        outline: none;
      }
    }

    .partner_program_price_list_tab_container {
      display: flex;
      flex-direction: column;
      padding: 32px;
      margin-top: 10px;
      box-shadow: 0px 4px 10px 0px $black-russian;
      gap: 32px;

      .partner_profile_grid_container {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .partner_profile_grid_wrapper {
          display: flex;
          flex-direction: column;
          gap: 32px;
          .partner_profile_grid_header_wrapper {
            display: flex;
            flex-direction: column;
            padding: 0px;
            gap: 8px;

            .partner_profile_grid_header {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              color: $secondary;
              text-transform: uppercase;
            }
          }
        }
      }

      .global_partner_program_price_list_container
        .toggle_section_heading_grid
        .MuiButtonBase-root {
        text-transform: capitalize;
        font-size: 16px;
      }

      .global_partner_program_price_list_container .toggle_section_body_content {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .partner_program_price_list_parent_container {
    width: 90%;

    .partner_program_price_list_child_container {
      width: 90%;

      .partner_program_price_list_main_heading {
        .MuiTypography-root {
          font-size: 28px;
        }
      }
    }
  }
}
