.main_product_card {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px $deep-ocean-blue;

  .card_grid {
    display: flex;
    flex-direction: column;

    .link_text {
      color: $primary;
      font-size: 16px;
      text-decoration: none;
    }
  }
}

.dashboard_card {
  width: 485px;

  .card_grid {
    gap: 10px;
    .product_image {
      width: 265px;
    }
  }
}

.product_card {
  width: 336px;

  .card_grid {
    gap: 20px;
    .product_image {
      width: 285px;
    }

    .MuiDivider-root {
      border: 1px solid $button-grey;
    }

    .card_content_grid {
      height: 135px;
      .card_content {
        color: $grid-black-text;
      }
    }
  }
}

.inactive_card {
  .card_grid {
    .card_content_grid {
      height: fit-content;
    }
  }
}
