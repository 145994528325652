.manage_quotes {
  padding: 54px;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  gap: 32px;
  height: 100%;

  .manage_quotes_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: $grid-black-text;
      border: none;
      outline: none;
    }

    .create_new_quote_btn {
      border-radius: 0px;
      text-transform: none;
      height: 48px;
      padding: 16px;
      gap: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;

      &:focus-visible {
        box-shadow: 0 0 0px 2px $pink-focus;
        outline: 1px solid $white-text;
        outline-offset: 0px;
      }
    }
  }
  .manage_quote_grid_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    background-color: $white;
    .custom_tab_panel_container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      .custom_tab_panel .custom_tabs {
        min-height: 24px;
      }

      .custom_tab_panel .custom_tabs .MuiTabs-flexContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 24px;
        flex-wrap: wrap;
      }

      .custom_tab_panel .custom_tabs .custom_each_tab {
        padding: 0px 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $secondary;
        min-height: 24px;
        text-transform: none;

        &:focus-visible {
          box-shadow: inset 0 0 0px 2px $pink-focus;
          outline-offset: -2px;
          box-shadow: inset;
        }
      }

      .custom_tab_panel .custom_tabs .Mui-selected.custom_each_tab {
        color: $primary;
      }

      .custom_tab_panel .custom_tabs .MuiTabs-indicator {
        display: none !important;
      }

      .custom_tab_panel_divider {
        border: 1px solid $pane-bg;
        width: 100%;
      }

      .custom_tab_panel_children {
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 32px;
        width: 100%;

        &.panel_children_disabled {
          display: none !important;
        }
      }
    }

    .manage_quote_grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .manage_quotes {
    padding: 15px;
    .manage_quotes_header {
      flex-wrap: wrap;

      h2 {
        font-size: 28px;
        margin-bottom: 5px;
      }
    }
    .manage_quote_grid_container{
      padding: 15px;
    }
  }
}
