
.blurred-background {
    filter: blur(4px); /* Apply blur effect */
  }
  
  .custom-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it overlays everything */
  
    .custom-loader span{
      width: 80px !important;
      height: 80px !important;
    }
  }
  