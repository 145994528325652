//-------colors------------
$bg-color: #def1e8;
$white: #ffffff;
$gray: #808080;
$light-gray: #ccc;
$black1: #000;
$black2: rgba(0, 0, 0, 0.2);
$black-hover: #e9ecef;
$light-grey-color: rgba(216, 216, 216, 0.5);
$black-card-shadow: rgba(0, 0, 0, 0.19);
$primary: #00a0fb;
$secondary: #002854;
$cetacean-blue: #00123b;
$pane-bg: #f0f0f0;
$white-text: #fafafa;
$pink-focus: #cc33ff;
$light-blue: #f1f9ff;
$grid-black-text: #231f20;
$scrollbar-grey: #888;
$scrollbar-grey-light: #555;
$blue-grey: #8094aa;
$button-grey: #d9dfe6;
$error_tomato: #b41f30;
$mercury: #e4e4e4;
$white-blue: #f1f9ff;
$success: #74d9ad;
$winter: #9dddf9;
$black-russian: #0000001a;
$columbian-blue: #ccd5de;
$semi-transparent-navy-blue: #00285440;
$golden-yellow: #ffc600;
$green: #00eb86;
$deep-ocean-blue: #0028541a;
