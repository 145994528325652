.partner_onboarding_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  min-height: 100vh;
  background-color: $white-text;

  .stepper_panel_container {
    margin-bottom: 150px;
    width: 60%;

    .stepper_container {
      .stepper_grid {
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 20px;
        gap: 5px;
        width: 30%;
        height: fit-content;

        .stepper_heading {
          font-size: 20px;
          color: $secondary;
          font-weight: 700;
        }

        .MuiStepper-root {
          .MuiStep-root .MuiStepLabel-label {
            font-size: 16px;
          }
        }
      }
      .stepper_components_grid {
        display: flex;
        justify-content: center;
        width: 63%;
      }
    }
  }

  .bottom_panel_container {
    .bottom_panel_buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

@media (max-width: 1600px) {
  .partner_onboarding_container .stepper_panel_container {
    width: 70%;
  }
  .partner_onboarding_container
    .stepper_panel_container
    .stepper_container
    .stepper_components_grid {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  .partner_onboarding_container .stepper_panel_container {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .partner_onboarding_container .stepper_panel_container {
    width: 85%;
  }
  .partner_onboarding_container
    .stepper_panel_container
    .stepper_container
    .stepper_components_grid {
    width: 75%;
  }
  .partner_onboarding_container .stepper_panel_container .stepper_container .stepper_grid {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .partner_onboarding_container .stepper_panel_container .stepper_container .stepper_grid {
    width: 100%;
    gap: 20px;
  }
  .partner_onboarding_container
    .stepper_panel_container
    .stepper_container
    .stepper_components_grid {
    width: 100%;
  }
  .partner_onboarding_container .stepper_panel_container .stepper_container {
    flex-direction: column;
  }
}
@import './FormsScreen.scss';
