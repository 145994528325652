.menu_button_dropdown {
  .MuiButtonBase-root {
    padding: 0px;
    width: 190px;
    justify-content: space-around;
    background: $white;
    color: $secondary;
    border: 1px solid $primary;
    border-radius: 0px;
    font-weight: 600;
    padding-left: 15px;
    text-transform: capitalize;
  }

  .MuiSvgIcon-root {
    border-left: 1px solid $primary;
    padding: 5px 0px;
    font-size: 40px !important;
  }
}
