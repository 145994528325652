.toggle_section_container{
    gap: 10px;
    .toggle_section_heading_grid{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MuiTypography-root{
            font-size: 20px;
            font-weight: 700;
        }
        .MuiButtonBase-root{
            color: $primary;
            display: none;
        }
    }
    .toggle_section_body_grid{
        margin-top: 10px;
        background-color: $white-blue;
        border: 1px solid $blue-grey;
        padding: 16px;
    }
}