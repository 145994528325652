.tnginfo_fields_container {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  background-color: $white;

  .fields_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    flex-wrap: wrap;

    .field_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      order: 0;
      flex-grow: 1;

      .field_name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $blue-grey;
      }

      .info_field_value {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 5px 16px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        flex-wrap: wrap;

        .field_value {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          color: $secondary;
        }

        .field_status {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 8px;
          gap: 4px;
          background: $white-text;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: $secondary;
        }
      }
    }

    .view_license_btn {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: $secondary;
      text-transform: none;
      background: $white-text;
      border: 1px solid $primary;
      border-radius: 0px;
      padding: 16px;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .fields_container_divider {
    border: 1px solid $pane-bg;
    width: 100%;
  }

  .tnginfo_detail_header {
    .MuiTypography-root {
      font-weight: 700;
      font-size: 14px;
    }
  }

  .tnginfo_fields {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    gap: 30px 24px;
    flex-wrap: wrap;
  }
}

.field_value_wrapper {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  min-width: 200px;
  min-height: 50px;
}

.field_value_wrapper .field_name {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $grid-black-text;
}

.field_value_wrapper .field_value {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: $secondary;
}

// media query for responsiveness
@media (max-width: 700px) {
  .tnginfo_fields_container {
    padding: 0px;
  }

  .tnginfo_fields_container .fields_header .view_license_btn {
    font-size: 15px;
    padding: 10px;
  }
}

@media (max-width: 500px) {
  .info_field_value .field_value {
    font-size: 24px !important;
  }

  .field_value_wrapper {
    min-width: 150px;
  }
}

@media (max-width: 400px) {
  .field_value_wrapper {
    min-width: 130px;
  }
}
