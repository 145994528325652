.user_detail_parent_container {
    gap: 15px;
    background: $white-text;
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    padding: 24px;

    .back_navigation_btn:focus {
        box-shadow: 0 0 0px 2px $pink-focus;
        outline: 1px solid $white-text;
        outline-offset: 0px;
        border-radius: 0px;
    }

    .MuiButtonBase-root.back_navigation_btn {
        color: $primary;
        text-transform: capitalize;
        font-size: 16px;
    }

    .MuiDivider-root {
        display: flex;
        width: 100%;
    }

    .user_detail_child_container {
        width: 100%;
        background-color: $white;
        gap: 20px;
        padding: 25px;

        .custom-loader {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .user_details_name_section_grid {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .user_name_label {
                color: $blue-grey;
                font-size: 14px;
            }

            .user_name_heading {
                font-weight: 700;
                font-size: 32px;
                color: $secondary;
            }

            .edit_user_details_btn {
                color: $secondary;
                font-weight: 700;
                border-radius: 0px;
                border: $primary 1px solid;
                height: 53px;
                text-transform: capitalize;
            }

            @media (max-width: 500px) {
                .edit_user_details_btn {
                    height: 70px;
                }
            }
        }

        .user_details_sub_grid {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0px;
            gap: 30px;
            flex-wrap: wrap;



            .field_value_wrapper {
                display: flex;
                gap: 8px;
                min-width: 200px;
                min-height: 50px;
                max-width: 400px;
            }

            .field_value_wrapper .field_name {
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                color: $grid-black-text;
            }

            .field_value_wrapper .field_value {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: $secondary;
                word-break: break-word;
            }

            .field_value_wrapper .field_value:last-child {
                white-space: pre-line;
            }
        }
    }
}