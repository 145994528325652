.my_profile_container {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .my_profile_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    width: 695px;
    background: $white-text;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 768px) {
      width: 600px;
    }

    @media screen and (max-width: 650px) {
      width: 500px;
    }

    @media screen and (max-width: 550px) {
      width: 450px;
      padding: 20px;
    }

    @media screen and (max-width: 500px) {
      width: 350px;
      padding: 10px;
    }

    .card_content_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;
    }

    .my_profile_card_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
      gap: 20px;

      .header_text {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $secondary;
      }

      .header_form_info {
        font-size: 16px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        color: $grid-black-text;
      }
    }
  }
}

// form
.my_profile_container .my_profile_card .profile_form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .profile_form_fields {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .form_row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    .form_field {
      @media screen and (max-width: 768px) {
        width: 80%;
      }
    }
  }
}
