/* Sidenav Drawer Styles */
.sidenav_drawer {
  .MuiDrawer-paper {
    width: 100% !important;
    background-color: $pane-bg;
    border: none;
    position: static;
  }
}

/* Sidenav List Container */
.sidenav_list_container {
  padding: 0 !important;

  .side_menu_list {
    padding: 0;

    .menu_links_section {
      display: flex;
      align-items: center;
      gap: 15px;
      width: 100%;

      a {
        display: flex;
        gap: 18px;
        text-decoration: none;
        color: $white;
        width: 100%;
        padding: 16px 0px 16px 0px;
        .MuiButtonBase-root {
          padding: 0;
        }
      }
    }

    .side_menu_list_item {
      .MuiAccordion-root {
        border-radius: 0;
        background-color: $secondary;

        .accordian_summary.MuiAccordionSummary-root {
          min-height: 60px;

          .MuiAccordionSummary-content {
            margin: 0;
          }

          &:hover {
            background-color: $primary;

            .MuiAccordionSummary-content .menu_links_section svg path {
              stroke: $white;
            }
          }

          .MuiAccordionSummary-content .menu_links_section .normal_heading {
            color: $white;
          }

          .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
            color: $white;
          }

          &:focus-visible {
            box-shadow: inset 0 0 0 2px $pink-focus;
            outline-offset: -2px;
            outline: 1px solid $white;
          }
        }

        .accordian_details.MuiAccordionDetails-root {
          padding: 0 !important;

          .side_menu_list_item_child {
            padding: 0;

            .MuiAccordion-root {
              background-color: $white !important;
              .accordian_summary.MuiAccordionSummary-root {
                .navigation_link svg path {
                  stroke: none !important;
                }
              }

              .accordian_summary.MuiAccordionSummary-root:hover {
                background-color: $light-blue;

                .menu_links_section {
                  svg path {
                    stroke: $secondary !important;
                  }

                  .navigation_link svg path {
                    stroke: none !important;
                  }
                }
              }

              .menu_links_section {
                a {
                  color: $secondary;
                  gap: 15px;
                  padding: 16px 0px 16px 0px;
                  width: 100%;
                }

                .navigation_link_grid {
                  display: flex;
                  width: 100%;
                  height: 20px;
                  justify-content: space-between;

                  a {
                    padding: 0;
                    width: fit-content;
                  }
                  a .MuiSvgIcon-root {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }

    .side_menu_list_item.active_panel {
      .MuiAccordion-root {
        background-color: $primary !important;
        border-left: 5px solid $white;
        .accordian_summary.MuiAccordionSummary-root {
          padding: 0px 11px;
          .MuiAccordionSummary-content .menu_links_section svg path {
            stroke: $white;
          }
        }
        .side_menu_list_item_child {
          .accordian_summary.MuiAccordionSummary-root {
            .MuiAccordionSummary-content .menu_links_section svg path {
              stroke: $blue-grey;
            }
          }
        }
      }
    }

    .side_menu_close {
      .menu_links_section {
        a .route_relative_link_text {
          display: none;
        }
        .normal_heading {
          display: none;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        display: none;
      }
    }
  }
}

/* Drawer Icon Container */
.drawer_icon_container {
  position: absolute;
  top: clamp(8.4%, 7vw, 9%);
  left: clamp(17.7%, 7vw, 20%);

  .MuiButtonBase-root {
    padding: 3px;
    background: $winter;
    border-radius: 100px;

    &:hover {
      background: $winter;
    }
    &:focus-visible {
      background: $winter;
    }
  }
}

.sidenav_drawer_icon_closed {
  top: clamp(9%, 7vw, 7%);
  left: clamp(2.3%, 7vw, 2.5%);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .drawer_icon_container {
    position: initial;
    display: grid;
    justify-content: flex-end;

    .MuiButtonBase-root {
      padding: 8px;
      border-radius: 0;
    }
  }
}

@media (max-width: 768px) {
  .sidenav_list_container {
    .side_menu_list {
      .menu_links_section {
        gap: 10px;

        a {
          font-size: 14px;
        }
      }

      .side_menu_list_item {
        .MuiListItem-root {
          padding: 10px;
        }

        .MuiAccordion-root {
          .accordian_summary.MuiAccordionSummary-root {
            padding: 8px;

            .MuiAccordionSummary-content .menu_links_section .normal_heading {
              font-size: 14px;
            }
          }
          .MuiAccordionDetails-root {
            .menu_links_section {
              .navigation_link_grid {
                justify-content: space-between;
                .MuiTypography-root {
                  max-width: 170px;
                  overflow-x: scroll;
                }
                .navigation_link {
                  width: 30px !important;
                }
              }
              .route_relative_link {
                .route_relative_link_text {
                  max-width: 170px;
                  overflow-x: scroll;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .sidenav_list_container {
    .side_menu_list {
      .menu_links_section {
        gap: 10px;

        a {
          font-size: 14px;
        }
      }

      .side_menu_list_item {
        .MuiListItem-root {
          padding: 10px;
        }

        .MuiAccordion-root {
          .accordian_summary.MuiAccordionSummary-root {
            padding: 8px;

            .MuiAccordionSummary-content .menu_links_section .normal_heading {
              font-size: 14px;
            }
          }
          .MuiAccordionDetails-root {
            .menu_links_section {
              .navigation_link_grid {
                justify-content: space-between;
                .MuiTypography-root {
                  max-width: 120px;
                  overflow-x: scroll;
                }
                .navigation_link {
                  width: 30px !important;
                }
              }
              .route_relative_link {
                .route_relative_link_text {
                  max-width: 120px;
                  overflow-x: scroll;
                }
              }
            }
          }
        }
      }
    }
  }
}
