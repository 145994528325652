.MuiButtonBase-root.back_navigation_btn {
    padding: 0;
    color: $primary;
    text-transform: capitalize;
    font-size: 16px;
}
.back_navigation_btn:focus {
    box-shadow: 0 0 0px 2px $pink-focus;
    outline: 1px solid $white-text;
    outline-offset: 0px;
    border-radius: 0px;
}
