.training_certification_parent_container {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #fafafa;

  .training_certification_children_container {
    width: 93%;
    height: fit-content;
    margin-top: 50px;
    gap: 20px;
  }

  .training_certification_back_navigation_grid {
    .MuiButtonBase-root {
      padding: 0;
      color: #00a0fb;
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
    }

    .MuiSvgIcon-root {
      color: #00a0fb;
    }
  }

  .training_certification_heading {
    .MuiTypography-root {
      font-size: 36px;
      font-weight: 700;
      border: none;
      outline: none;
    }
  }

  .training_certification_grid_container {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
}
