.reset_password_parent_container {
  width: 100%;
  display: flex;
  justify-content: center;

  .reset_password_image {
    margin-bottom: 35px;

    img {
      padding: 28px 0px 0px 33px;
    }
  }

  .reset_password_children_container {
    width: 420px;
    gap: 20px;

    .navigation_btn {
      border-radius: 0;
      color: $primary;
      text-transform: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      padding: 0px;
      gap: 8px;
      text-decoration-line: underline;

      .MuiButton-icon {
        height: 24px;
        width: 24px;
        align-items: center;
        margin-right: 0px;
      }

      .MuiButton-icon .MuiSvgIcon-root {
        font-size: 24px !important;
      }
    }

    .reset_password_message_grid {
      border: 1px solid $winter;
      background-color: $white-blue;
      padding: 13px;
    }

    .reset_password_form {
      gap: 20px;

      .reset_password_form_heading .MuiTypography-root {
        font-weight: 700;
        font-size: 32px;
        color: $secondary;
      }

      .reset_password_form_resend_code_button {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiButtonBase-root {
          width: fit-content;
          height: fit-content;
          padding: 0;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .btn_disabled.MuiButtonBase-root {
          color: $blue-grey;
        }

        .btn_enabled.MuiButtonBase-root {
          color: $primary;
        }

        .MuiTypography-root {
          color: $secondary;
        }
      }

      .reset_password_form_submit_button {
        margin-bottom: 30px;

        &.MuiButtonBase-root {
          padding: 0;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          height: 53px;
          width: 153px;
          background-color: $primary;
          color: $white;
          border-radius: 0;
        }
      }

      .reset_password_input_fields {
        .MuiFormLabel-root {
          color: $grid-black-text;
          margin-bottom: 5px;
          margin-top: 20px;
        }

        .MuiInputBase-input {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: $secondary;
          padding: 16px;
          height: 1rem;
        }

        .MuiInputBase-root {
          border-radius: 0;
          background: $pane-bg;
          border: 1px solid $blue-grey;
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }

      .reset_password_password_rules {
        .MuiListItem-root {
          display: flex;
          align-items: center;
          padding: 0;
          margin-bottom: 5px;
          color: $grid-black-text;

          &.untouched {
            color: $blue-grey;

            .circle {
              background: $blue-grey;
            }
          }

          &.valid .circle {
            background: $primary;
          }

          &.invalid .circle {
            background: $error_tomato;
          }
        }

        .circle {
          background: $primary;
          border-radius: 50%;
          margin-right: 10px;
        }

        .circle_normal {
          width: 10px;
          height: 10px;
        }

        .circle_special {
          width: 15px;
          height: 10px;
        }
      }

      .MuiFormHelperText-root {
        margin-left: 0;
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}