.navbar_parent_container {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, $cetacean-blue 0%, $secondary 69.27%, $primary 100%);

    .nav_logo {
        margin-left: 20px;
        width: 138px;
        height: 35px;
    }

    .navbar_content_container {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 16px;
        margin-left: -15px;
    }

    .MuiDivider-root {
        border: 1px solid $blue-grey;
        height: 16px
    }


    .navbar_notification_icon .MuiButtonBase-root {
        padding: 0;
    }

    .navbar_notification_icon .MuiSvgIcon-root {
        color: $primary;
    }

    .navbar_username_text .MuiTypography-root {
        font-weight: 500;
        color: $white-text;
    }
}

@media (max-width: 600px) {
    .navbar_parent_container {
        height: 100px;

        .nav_logo {
            padding-top: 5px;
            width: 100px;
        }

        .navbar_content_container {
            justify-content: flex-start;
            margin-left: 0;
            gap: 2px;
        }

        .navbar_username_text .MuiTypography-root {
            font-size: 10px;
        }

        .MuiDivider-root {
            margin-left: 20px;
        }
    }
}

.navbar_popover {

    .MuiPopover-paper {
        width: 258px;
        height: fit-content;
        display: flex;
        border-radius: 0;
        margin-top: 4px;
        margin-left: 15px;
    }

    .MuiList-root {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .MuiListItem-root {
        padding: 0;
        a {
            font-weight: 500;
            text-decoration: none;
            color: $secondary;
            justify-content: end;
            padding: 12px 16px;
            width: 100%;
        }
        .MuiButtonBase-root{
            padding: 12px 16px;
            font-size: 16px;
            color: $secondary;
            justify-content: end;
            border-radius: 0;
            width: 100%;

            &:hover {
                background-color: initial;
            }
        }
    }

    .popover_list .MuiDivider-root {
        width: 90%;
        color: $pane-bg
    }

    .popover_list .popover_list_item:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }

    .popover_list .popover_list_item .popover_list_link {
        display: flex;
    }

    .popover_list .popover_list_item .MuiSvgIcon-root {
        color: $secondary;
        height: 16px;
        width: 16px;
        margin-top: 4px;
        margin-left: 4px;
    }
}

.alert_box_dialog {
    .MuiDialog-paper {
        width: 80%;
        border-radius: 8px;
    }

    .MuiDialogTitle-root {
        padding: 0px;
    }

    .alert_box_close_container {
        .alert_box_close_button {
            display: flex;
            justify-content: end;

            .MuiButtonBase-root {
                gap: 3px;
                font-size: 16px;
                font-weight: 500;
                color: $primary;
                padding: 0px;
                margin: 12px 10px 5px 0px;
            }

            .MuiSvgIcon-root {
                margin-top: 1px;
                width: 20px;
                height: 20px;
            }
        }

        .alert_box_close_button .MuiButtonBase-root:focus-visible{
            border-radius: 0;
        }
    }

    .MuiDialogContent-root {
        padding: 0px;
        margin-bottom: 40px;
    }

    .alert_box_content {
        width: 88%;
        margin: 0 auto;
        gap: 24px;

        .alert_box_title .MuiTypography-root{
            font-size: 20px;
            font-weight: 700;
        }

        .MuiDivider-root {
            border: 1px solid $blue-grey;
            width: 100%;
        }

        .alert_box_content_text .MuiTypography-root {
            font-weight: 500;
            color: $secondary;
        }

    }

    .MuiDialogActions-root {
        padding: 0px;
    }

    .alert_box_button_container {
        justify-content: end;
        gap: 20px;
        width: 88%;
        margin: 0 auto;
        margin-bottom: 20px;

        .MuiButtonBase-root {
            height: 53px;
            border-radius: 0px;
            font-size: 16px;
            text-transform: capitalize;
            font-weight: 600;
        }

        .alert_box_primary_button .MuiButtonBase-root {
            color: $primary;
            border: 1px solid $primary;
        }

        .alert_box_confirm_button .MuiButtonBase-root {
            color: $error_tomato;
            border: 1px solid $error_tomato;
        }
    }
}