.change_password_container {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .chnage_password_card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      width: 695px;
      background: $white-text;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      @media screen and (max-width: 768px) {
        width: 600px;
      }
    }
}