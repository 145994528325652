/* Styles for the filter drawer component */
.filter_drawer .MuiPaper-root {
  width: 21%;

  /* Responsive adjustments for different screen sizes */
  @media screen and (max-width: 490px) {
    width: 60%;
  }

  @media screen and (max-width: 992px) {
    width: 60%;
  }
}

/* Styles for the close button within the filter drawer */
.filter_drawer .drawer_close_button .MuiIconButton-root {
  padding: 0px 0px 0px 8px;
  display: flex;
  align-items: center;
  &:focus-visible {
    border-radius: 0px;
  }
}

.filter_drawer .drawer_close_button .MuiTypography-root {
  color: $primary;
  font-weight: 500;
}

/* Styles for the divider within the filter drawer */
.filter_drawer .MuiDivider-root {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  color: $pane-bg;
}

/* Styles for the filter heading within the filter drawer */
.filter_drawer .drawer_filter_heading .MuiTypography-root {
  font-size: 24px;
  font-weight: 900;
  color: $grid-black-text;
  margin-top: 15px;
  letter-spacing: 1px;
}

/* Styles for the selected values text within the filter drawer */
.filter_drawer .order_status_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Styles for the order status text within the filter drawer */
.filter_drawer .order_status_text .MuiTypography-root {
  font-weight: 700;
  color: $grid-black-text;
  font-size: 16px;
  margin-bottom: 5px;
}

/* Styles for the order status checkbox within the filter drawer */
.filter_drawer .order_status_checkbox .MuiCheckbox-root {
  padding: 0px 5px 0px 0px;
  margin-left: 8px;
}

.filter_drawer .order_status_checkbox .MuiFormControlLabel-root {
  display: flex;
  align-items: center;
  padding: 3px 0px;
}

/* Styles for the checked state of the order status checkbox */
.filter_drawer .order_status_checkbox .Mui-checked {
  color: $primary;
  border: 1px $primary;
}

/* Styles for the user dropdown within the filter drawer */
.filter_drawer .user_dropdown .MuiAutocomplete-root {
  //width: 336px;

  /* Responsive adjustments for different screen sizes */
  @media screen and (max-width: 490px) {
    //width: 150px;
  }
}

.MuiAutocomplete-listbox .MuiListItemText-root .MuiTypography-root {
  word-wrap: break-word;
}

/* Styles for the input base within the user dropdown */
.filter_drawer .user_dropdown .MuiInputBase-root {
  border-radius: 0px;
  height: 48px;
  background: $pane-bg;
  border: 1px solid $blue-grey;

  input {
    padding: 0px;
  }
}

.filter_drawer .dropdown_search_button {
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButtonBase-root {
    padding: 13px;
    border-radius: 0px;
    border: 1px solid $primary;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: $secondary;
  }
}

.filter_drawer .po_date_range {
  @media screen and (max-width: 1440px) {
    margin-top: 10px;
    text-align: center;
  }
}

.filter_drawer .po_date_range .MuiTextField-root {
  width: 97%;
}

/* Styles for the date range picker within the filter drawer */
.filter_drawer .po_date_range .MuiInputBase-root {
  width: 100%;
  border-radius: 0px;
  background: $pane-bg;
  border: 1px solid $blue-grey;
  color: $blue-grey;
  box-shadow: 0px 0px 8px 0px #0000001a inset;
}

.filter_drawer .po_date_range .MuiTypography-root {
  font-weight: 700;
  color: $grid-black-text;
  font-size: 16px;
  margin-bottom: 7px;
  text-align: left;
}

.filter_drawer .po_date_error {
  margin: 10px 0px 5px 0px;

  .MuiTypography-root {
    font-size: 16px;
  }
}

/* Styles for the apply button within the filter drawer */

.filter_drawer .filter_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .MuiInputBase-root {
    &:hover {
      border: 1px solid $blue-grey;
    }
    &:focus-within {
      border: none;
      box-shadow: 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      border-radius: 0px;
      z-index: 1;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

/* Styles for the button within the apply button container */
.filter_drawer .drawer_apply_button {
  width: 100%;
  margin-bottom: 20px;
  flex: 1;
  display: flex;
  align-items: end;
  .MuiButton-root {
    border-radius: 0px;
    background: $primary;
    color: white;
    font-weight: 700;
    height: 53px;
    padding: 16px;
  }
}

/* Styles for the disabled state of the apply button */
.filter_drawer .drawer_apply_button_disabled .MuiButton-root {
  background: $blue-grey;
}

/* Styles for the chip within the user dropdown */
.filter_drawer .user_dropdown .MuiChip-root {
  display: none;
}

/* Styles for the clear indicator within the user dropdown */
.filter_drawer .user_dropdown .MuiAutocomplete-clearIndicator {
  display: none;
}

.filter_drawer .user_dropdown {
  .dropdown_search_button {
    display: flex;
    align-items: center;
    justify-content: end;

    .MuiButtonBase-root {
      padding: 13px;
      border-radius: 0px;
      border: 1px solid $primary;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
      color: $secondary;
    }
  }

  .error_text {
    margin-top: 5px;
    font-size: 14px;
    color: $error_tomato;
  }
}
