.account_created_parent_container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  height: 100%;

  .account_created_child_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 14%;
    .check_icon {
      width: 46px;
      height: 46px;
    }
    .account_created_content_grid {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .account_created_heading {
        font-weight: 700;
        font-size: 32px;
      }
      .account_created_text {
        color: $grid_black_text;
      }
    }
    .account_created_button {
      background-color: $primary;
      border-radius: 0px;
      color: $white;
      padding: 16px;
      text-transform: none;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    .account_created_child_container {
      width: 80%;
      gap: 30px;
      .check_icon {
        width: 40px;
        height: 40px;
      }
      .account_created_content_grid {
        .account_created_heading {
          font-size: 28px;
        }
      }
    }
  }
}
