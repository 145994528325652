@mixin icon-size($height: 15px, $width: 15px) {
  height: $height;
  width: $width;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin responsive($breakpoint) {
  @if $breakpoint ==mobile {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint ==tablet {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  }

  @else if $breakpoint ==desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }
}

@mixin transition($property, $duration) {
  transition: $property $duration ease-in-out;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}