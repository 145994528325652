// mobile responsiveness
.login_layout_container {
  height: 100vh;

  #main-content{
    background-color: $pane-bg;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
    .login_layout_container .container_first_child {
      display: none;
    }
    .login_layout_container .container_second_child {
      min-width: 100% !important;
    }
  }
  