.form_field {
  flex: 1;

  .MuiFormHelperText-root {
    color: $error_tomato;
    margin: 0 !important;
    padding-top: 5px;
    background-color: $white-text;
    font-size: 14px;
  }
  .form_field_input_label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: $secondary;
    margin-bottom: 8px;
    text-wrap: wrap;
  }

  .form_input_field {
    &.form_textarea_field .MuiInputBase-root {
      padding: 0px;
    }
    .MuiInputBase-input {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $secondary;
      padding: 16px;
      height: 1rem;
    }

    .MuiInputBase-root {
      border-radius: 0;
      background: $pane-bg;
      border: 1px solid $blue-grey;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    &.form_input_field_error {
      .MuiInputBase-root,
      .MuiSelect-select {
        border: 1px solid $error_tomato !important;
      }
    }

    .MuiOutlinedInput-root:focus-within {
      border: none;
      box-shadow: 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      border-radius: 0px;
      z-index: 1;
    }

    .password_icon_button {
      padding: 0px !important;

      svg {
        height: 15px;
        width: 15px;
      }
    }

    &.select_field {
      .MuiSelect-select {
        border-radius: 0;
        background: $pane-bg;
        border: 1px solid $blue-grey;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        min-height: 1rem !important;
        height: 1rem !important;

        &:focus-visible {
          border: none;
          box-shadow: 0px 0px 0px 2px $pink-focus;
          outline: 1px solid $white;
          border-radius: 0px;
        }
      }
    }

    .Mui-disabled {
      background-color: $white-blue;

      &.MuiInputBase-root {
        border-radius: 0;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.form_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;

  .save_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    background-color: $primary;
    border: none;
    border-radius: 0;
    color: $white;
    font-weight: 700;
    width: 100%;
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;

    &:focus-visible {
      box-shadow: 0px 0px 0px 2px $pink-focus;
      outline: 1px solid $white;
      outline-offset: 0px;
    }

    &.button_disabled {
      background-color: $blue-grey;
      color: $white-text;
    }

    &.button_loader {
      background: $primary !important;
      color: $white-text;
    }

    &:hover {
      background-color: $primary;
    }
  }
}

// for chip fields
.chip_parent_container {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  .form_field_input_label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $secondary;
    text-wrap: wrap;
  }

  .chip_child_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    gap: 8px;

    .chip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 10px;
      background: $white-text;
      border: 1px solid $mercury;
      border-radius: 100px;
      height: 37px;

      &:focus-within {
        box-shadow: 0 0 0px 2px $pink-focus;
        outline: 1px solid $white-text;
        outline-offset: 0px;
      }
    }
    .MuiChip-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $secondary;
    }
    .selected_chip {
      background: $white-blue;
      border: 1px solid $primary;

      .MuiChip-label {
        font-weight: 600;
      }
    }
  }
}
// for checkbox field
.form_checkbox_field {
  &.Mui-focusVisible {
    border: none;
    box-shadow: 0px 0px 0px 2px $pink-focus;
    outline: 1px solid $white;
    border-radius: 0px;
  }
  &.Mui-checked {
    color: $primary !important;
  }
}
