.partner_program_guide_parent_container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: $white;
  height: 100%;

  .partner_program_guide_child_container {
    width: 93%;
    margin-top: 50px;
    gap: 10px;

    .partner_program_guide_main_heading {
      .MuiTypography-root {
        color: $secondary;
        font-size: 36px;
        font-weight: 700;
        border: none;
        outline: none;
      }
    }

    .partner_program_guide_tab_container {
      padding: 30px;
      box-shadow: 0px 4px 10px 0px #0000001a;
      margin-bottom: 30px;

      .custom_tab_panel_container {
        gap: 15px;

        .MuiTabs-flexContainer {
          gap: 20px;
          padding: 2px;

          .MuiButtonBase-root{
            min-height: 0px;
          }
        }

        .custom_tabs{
          min-height: 0px;
        }

        .MuiButtonBase-root {
          padding: 0;
          font-size: 16px;
          text-transform: capitalize;
        }

        .MuiTabs-indicator {
          display: none;
        }

        .custom_tab_panel_divider {
          width: 100%;
        }

        .custom_tab_panel_children {
          width: 100%;

          .public_sector_program.MuiTypography-root {
            font-size: 20px;
            font-weight: 700;
          }
        }

        .custom_tabs .MuiButtonBase-root {
          color: $secondary;
        }

        .custom_tabs .MuiButtonBase-root.Mui-selected {
          color: $primary;
        }
      }

      .partner_program_grid_container {
        margin-top: 20px;

        .cell_with_File_Icon {
          padding: 0;
        }

        .cell_with_download_action .MuiButtonBase-root {
          color: $primary;
          text-transform: capitalize;
          padding: 0;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .partner_program_guide_parent_container {
    width: 90%;

    .partner_program_guide_child_container {
      width: 90%;

      .partner_program_guide_main_heading {
        .MuiTypography-root {
          font-size: 28px;
        }
      }

      .MuiTabs-flexContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
