@import './abstract/index.scss';
@import './base/index.scss';
@import './components/index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'RedHatDisplay-Medium';
}

//main s508 styling for interactive elements
a:focus-visible,
button:focus-visible {
  box-shadow: 0 0 0px 2px $pink-focus;
  outline: 1px solid $white-text;
  outline-offset: 0px;
}

input[type='password']::-ms-reveal {
  display: none;
}

/* Hide the up and down arrow icons for all number inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
