@font-face {
  font-family: 'RedHatDisplay-Black';
  src: url('../../fonts/RedHatDisplay-Black.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-BlackItalic';
  src: url('../../fonts/RedHatDisplay-BlackItalic.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Bold';
  src: url('../../fonts/RedHatDisplay-Bold.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-BoldItalic';
  src: url('../../fonts/RedHatDisplay-BoldItalic.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Medium';
  src: url('../../fonts/RedHatDisplay-Medium.ttf');
}

.ff-Bl {
  font-family: 'RedHatDisplay-Black';
}

.ff-BlI {
  font-family: 'RedHatDisplay-BlackItalic';
}

.ff-Bo {
  font-family: 'RedHatDisplay-Bold';
}

.ff-BoI {
  font-family: 'RedHatDisplay-BoldItalic';
}
.ff-M {
  font-family: 'RedHatDisplay-Medium';
}
