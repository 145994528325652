// remove border from ag-grid
.ag-theme-alpine .ag-root-wrapper {
  border: none;
  border-radius: 0px;
}

.ag-theme-alpine .ag-header,
.ag-theme-alpine .ag-body-viewport,
.ag-theme-alpine .ag-footer {
  border: none;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-row,
.ag-theme-alpine .ag-header-cell {
  border: none;
}

.ag-theme-alpine .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
  outline: 1px solid $white !important;
  outline-offset: -2px !important;
  box-shadow: inset 0 0 0px 2px $pink-focus;
}

/* Remove hover effects from AG Grid header cells */
.ag-theme-alpine .ag-header-cell:hover {
  background-color: $pane-bg !important;
}

/* Apply custom styles to all scrollbars */
.ag-theme-alpine ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-theme-alpine .ag-header {
  z-index: 3;
}

.ag-theme-alpine ::-webkit-scrollbar-thumb {
  background: $scrollbar-grey;
  border-radius: 10px;
}

.ag-theme-alpine ::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-grey-light;
}

.ag-theme-alpine.ag_grid_container {
  width: 100%;
  height: auto;
  overflow-y: auto;
  min-height: 300px;

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
  }

  .ag-root.ag-layout-normal {
    min-height: 300px;
  }

  .ag-header-container {
    background-color: $white;
    width: 100% !important;

    .ag-header-row {
      width: 100% !important;
    }
  }

  .ag-header-cell {
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    background: $pane-bg;
    cursor: pointer;
    color: $grid-black-text;
    padding: 0px 24px;

    .ag-header-cell-resize {
      right: -6px;
      &::after {
       display: none;
      }
    }
  }

  .ag-header-cell:not(:last-child) {
    border-right: 1px solid $white-text;
  }

  .ag-header-cell.ag-focus-managed::after {
    border-color: $white !important;
    box-shadow: 0 0 0px 2px $pink-focus;
  }

  .ag-row {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $grid-black-text;
  }

  .ag-row:nth-child(even) {
    background-color: $white-blue;
  }

  .ag-row:nth-child(odd) {
    background-color: $white;
  }

  .ag-row .ag-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $secondary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .ag-cell-wrapper {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ag-header-cell.ag-grid-sorted-column-header {
    border: 1px solid $blue-grey;
  }
}

@import './customComponents.scss';
