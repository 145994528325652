.order_history {
  padding: 54px;
  background-color: $white-text;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  gap: 32px;
  height: 100%;

  .order_history_header h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: $grid-black-text;
    border: none;
    outline: none;
  }

  .order_history_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px;
    gap: 16px;
    background-color: $white;
    width: 100%;

    .order_history_divider {
      width: 100%;
      border: 1px solid $pane-bg;
    }
    .custom_tabs button{
      text-transform: capitalize;
      color: $secondary;
    }
    
    .custom_each_tab.Mui-selected{
      border: 2px solid $pane-bg; 
     background-color: $pane-bg;
     color: $primary;
    }
    .custom_tabs .Mui-focusVisible {
        border: 2px solid $pink-focus;
        outline: none;
        box-shadow: none;
    }
   
    .MuiTabs-scroller{
      margin-bottom: 10px !important;
    }
    .MuiTabs-indicator{
      display: none;
    }
    .custom_tab_panel_container .custom_tab_panel_children{
      width: 100% !important;
    }
  }
}

.filter_button_container {
  display: flex;
  align-items: center;
  height: fit-content;
  margin-left: 10px;
  gap: 10px;
  flex-wrap: wrap;

  .filter_button {
    background: #f1f9ff;
    padding: 0px;
    border-radius: 0px;
    height: 48px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  .filter_notapplied .filter_button {
    width: 48px;
  }

  .filter_applied .filter_button {
    padding: 8px 12px 8px 12px;
    border: 1px solid $primary;
    color: $grid-black-text;
    gap: 10px;
    text-transform: none;
  }

  .filter_clear .MuiButtonBase-root {
    background: none;
    border: none;
    color: $primary;
    text-transform: initial;
  }

  .MuiSvgIcon-root {
    color: #002854;
  }
}

// media query for responsiveness

@media (max-width: 700px) {
  .order_history {
    padding: 40px;
  }
}

@media (max-width: 500px) {
  .order_history {
    padding: 20px;

    .order_history_header h2 {
      font-size: 28px;
    }
  }
}
